@font-face {
    font-family: 'Big Noodle Titling';
    src: local('BigNoodleTitling'), url('../Fonts/big_noodle_titling.ttf') format('truetype');
}

@font-face {
    font-family: 'Gotham Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham Medium'), url('../Fonts/GothamMedium.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Book';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Book'), url('../Fonts/Gotham-Book.otf') format('woff');
}

html, body, #root {
    height: 100%;
}

.visitor-queue-title-container {
    padding: 0;
    margin: 0;
}

.visitor-queue-title {
    padding: 30px;
    text-align: center;
    font-size: 72px;
    font-weight: bolder;
}

.visitor-queue-container {
    background-color: black;
    color: white;
    max-width: 100%;
    min-height: 100%;

    line-height: normal;
}

.wallboard-config-input {
    margin-bottom: 10px !important;
}

.title {
    font-family: "Rockwell", "Arial", "Myriad Pro", serif;
}

.title .title-text {
    text-transform: uppercase;
    border-bottom: 5px solid #BF0D3E
}

.dot {
    height: 25px;
    width: 25px;
    background-color: lightgray;
    border-radius: 50%;
    display: inline-block;
}

.dot.active {
        background-color: rgb(37, 44, 38);
}

.visitor-queue-table-row {
    display: flex;
    width: 100%;
    padding: 30px 0;
}

.visitor-queue-table-cell {
    display: inline-block;
    padding-top: 25px;
    padding-left: 15px;

    font-size: 1.25em !important;
    font-family: "Gotham Medium", "Arial", "Myriad Pro", sans-serif !important;
    line-height: normal !important;
    letter-spacing: normal !important;
}

.visitor-queue-name {
    padding-bottom: 10px;
}

.visitor-queue-table {
    padding: 40px;
}

.visitor-queue-info {
    padding-left: 5px;
}

.visitor-queue-position {
    font-weight: bolder;
    font-size: 1.5em;
    padding-top: 20px;
}

.visitor-queue-name {
    font-weight: bold;
    font-size: 1.1em;
    font-family: "Gotham Medium";
}

.visitor-queue-icon {
    width: 48px;
    display: block;
    height: 48px;
    background-size: contain;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 10px;
    float: left;
}

.visitor-queue-icon img {
    width: 48px;
    height: 48px;
}

.visitor-queue-cell {
    display: inline-block;

}

.visitor-queue-icon-container {
    padding-top: 5px;
}

.visitor-queue-footer {
    background-image: linear-gradient(to right, #b3b3b3 33%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 9px 1px;
    background-repeat: repeat-x;
    padding-bottom: 15px;
    width: 95%;
}

.icon-Haircuts {
    background-image: url('../Icons/scissors.png');
}

.icon-Shaves {
    background-image: url('../Icons/razor.png');
}

.icon-Detailing {
    background-image: url('../Icons/comb.png');
}


.side-nav-item {
    font-size: 1em;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    color: rgba(255,255,255,0.9) !important;
    text-shadow: 2px 2px black;
}

.active li {
    color: #BF0D3E !important;
}

.side-nav-item .material-icons {
    padding-right: 45px;
}

.side-nav-link {
    text-decoration: none;
}

.d-flex {
    display: flex;
    flex-wrap: wrap;
}

button .material-icons {
    margin-right: 0.5em;
}

body {
    margin: 0;
    padding: 0;
    font-size: 1.4em;
    font-family: "Gotham Medium", "Arial", "Myriad Pro", sans-serif;
    color: rgb(37, 44, 38);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.promotion-container {
    width: 100%;
    border: none;
    min-height: 225px;
}

.promotion-image {
    margin: 0;
    width: 100%;
    vertical-align: bottom;
}


 
.component-grid-item > * {
    max-width: none !important;
}

.btn-new {
    margin-bottom: 20px !important;
}
.tg-barbers-category {
    font-family: 'Big Noodle Titling';
    position: relative;
}

.tg-small-services {

}

.tg-small-services .tg-service-square__price {
    display: none;
}
.tg-small-services .tg-service-square__info {
    display: none;
}

.tg-small-services .tg-service-square {
    padding: 20px !important;
}

.tg-mobile-keyboard .hg-button-bksp {
    max-width: 33%;
}

.tg-summary-keyboard.hg-theme-default .hg-button,
.tg-mobile-keyboard.hg-theme-default .hg-button {
    font-size: 21px;
    height: 68px;
}

.tg-kiosk-logo-container {
    padding-top: 50px;
}

/* Ipad Pro in Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    .tg-kiosk-logo {
        margin-top: -200px;
        height: 150px;
    }
}

@media only screen and (max-width: 450px) {
    .tg-summary-keyboard.hg-theme-default .hg-button, .tg-mobile-keyboard.hg-theme-default .hg-button {
        font-size: 17px;
    }
}
                                                                                                      
.center-input input {
    text-align: center;
}

input#phoneNumber {
    text-align: center;
}

.tg-summary-input #wait-delay, .tg-summary-input #reminder {
    background-color: #fff;
}

.tg-services-container::-webkit-scrollbar {
    display: none;
}

.tg-category-container {
    padding-right: 25px;
    position: relative;
}

.tg-next-button {
    background-color: #BF0D3E;
    width: 200px;
    min-width: 200px;
    height: 80px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.2;
}

.tg-next-button-mobile {
    background-color: #BF0D3E;
    padding: 5px 18px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}

.tg-back-button {
    width: 200px;
    min-width: 200px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.tg-back-button {
    width: 200px;
    cursor: pointer;
}
.tg-back-button .tg-back-button-text {
    text-align: center;
    font-size: 40px;
    font-family: "Big Noodle Titling";
}

.tg-back-button .tg-back-button-arrow-container {
    cursor: pointer;
}

.tg-back-button .tg-back-button-arrow {
    color: #BF0D3E;
    font-size: 55px;
    cursor: pointer;
}

.tg-services-overlay {
    position: absolute;
    height: 492px;
    width: 99%;
    pointer-events: none;
    z-index: 1000;
    right: 24px;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.9), transparent 20%);
}

.tg-services-overlay-mobile {
    position: absolute;
    height: 200px;
    width: 100%;
    pointer-events: none;
    z-index: 1000;
    right: 15px;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.9), transparent 20%);
}

.tg-service-category-group {
    margin-left: -10px;
}

.tg-services-mobile {
    width: 95%;
}

.tg-overflow-y-overlay {
    position: fixed;
    bottom: 0px;
    height: 200px;
    width: 100%;
    pointer-events: none;
    z-index: 1000;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent 40%);
}


/*Bootstrap grid style column definitions below*/
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col {
    position: relative;
    width: 100%;
    display: inline-block;
}

    .col-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

.btn-disable {
    pointer-events: none; 
    opacity: 0.7;
}